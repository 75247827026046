<template>
    <div class="con">
        <div class="top_box">
            <div class="top_left">
<!--                <div class="top_h1">全球ID</div>-->
<!--                <div class="top_h2">24小时发货</div>-->
                <div class="top_h1">新派科技</div>
            </div>
            <div class="top_right">
                <el-button type="primary" icon="el-icon-search" size="small">订单查询</el-button>
            </div>
        </div>
<!--        <div class="app_box">-->
<!--            <div class="app_list">-->
<!--                    <p style="font-size: 18px">网站开发</p>-->
<!--                    <p style="font-size: 18px">小程序开发</p>-->
<!--                    <p style="font-size: 18px">安卓开发</p>-->
<!--                    <p style="font-size: 18px">苹果开发</p>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="app_box">-->
<!--            <div class="app_list">-->
<!--                <div v-for="item in appList" class="app_item">-->
<!--                    <img :src="item.url" alt="">-->
<!--                    <p>{{item.name}}</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="notice_box">
<!--            <p style="padding: 5px 15px">外应用和帐号管控严格，使用过程需要网络支持，小白慎拍，暂不提供网络服务。</p>-->
            <p style="padding: 5px 15px">定制化开发，具体需求请联系客服人员。<br/>具体价格根据实际需求，下单前请先和客服确认后再下单付款。</p>
        </div>
        <div class="app_detail">
            <div class="detail_box">
                <div class="detail_img"><img :src="chargeItem.img_url" alt=""></div>
                <div class="detail_info">
                    <div style="font-weight: 600">{{chargeItem.title}}</div>
                    <div class="detail_num">
                        <div>已售{{chargeItem.num}}份</div>
                        <div class="detail_num_btn">
                            <div style="font-weight: 600">购买数量：</div>
                            <div class="num_btn" @click="handleNum(-1)">-</div>
                            <div class="num_text">{{num}}</div>
                            <div class="num_btn" @click="handleNum(1)">+</div>
                        </div>
                    </div>
                    <ul>
<!--                       <li>账号</li>-->
<!--                       <li>自动发货</li>-->
<!--                       <li>库存充足</li>-->
<!--                       <li>独享ID</li>-->
                        <li>定制开发</li>
                        <li>服务至上</li>
                        <li>经验丰富</li>
                    </ul>
                    <div style="color: red;font-weight: 600">￥{{chargeItem.price}}</div>
                </div>
            </div>
        </div>
        <div class="goods_box">
            <div class="goods_h1">支付方式</div>
            <div class="pay_list">
                <div v-for="(item,index) in payList" :style="'border:1px solid '+(payActive==index?'red':'#E0E0E0')" class="pay_item" @click="handlePay(index)">
                    <div class="item_left">
                        <img :src="item.icon" alt="">
                        <div class="goods_title">{{item.name}}</div>
                    </div>
                    <div class="item_right">{{item.remark}}</div>
                </div>
            </div>
        </div>
        <div class="goods_box">
            <div class="goods_h1">规格</div>
            <div class="goods_list">
                <div v-for="(item,i) in chargeList" :style="active==i?'background:#409EFF;color:white':''" class="goods_item" @click="handleClick(i)">
                    <div class="goods_title">{{item.title}}</div>
                    <div :style="active==i?'color:white':''" class="goods_price">￥{{item.price}}</div>
                    <div class="goods_tip" v-if="item.remark">{{item.remark}}</div>
                </div>
            </div>
        </div>
        <div style="height: 60px"></div>
        <div class="btn_box">
            <div class="btn_t1" @click="contact"><img src="../assets/kefu.png" alt="">在线客服</div>
            <div>合计金额：<span style="color: red;font-size: 1rem">￥</span><span style="color: red;font-size: 1.5rem">{{totalPrice}}</span></div>
            <button type="button" class="submit_btn" @click="startOrder">提交订单</button>
<!--            <el-button type="danger" round size="normal">提交订单</el-button>-->
        </div>
        <div v-html="payContent"></div>
    </div>
</template>

<script>
    import Android from "@/assets/android.png";
    import Facebook from "@/assets/facebook.png";
    import Gmail from "@/assets/gmail.png";
    import Ins from "@/assets/ins.png";
    import Tk from "@/assets/tk.png";
    import Vk from "@/assets/vk.png";
    import X from "@/assets/x.png";
    import Kwai from "@/assets/kwai.jpg";
    import Youtube from "@/assets/youtube.png";
    import axios from "axios";

    export default {
        name: "Home",
        data() {
            return {
                // 展示应用列表
                appList: [
                    {
                        "name": "推特账号",
                        "url": X
                    },
                    {
                        "name": "Instagram",
                        "url": Ins
                    },
                    {
                        "name": "Tiktok",
                        "url": Tk
                    },
                    {
                        "name": "Kwai",
                        "url": Kwai
                    },
                    {
                        "name": "Youtube",
                        "url": Youtube
                    },
                    {
                        "name": "脸书账号",
                        "url": Facebook
                    },
                    {
                        "name": "谷歌邮箱",
                        "url": Gmail
                    },
                    {
                        "name": "安卓应用",
                        "url": Android
                    }
                ],
                active: 0,
                payActive: 0,
                num: 1, //选购数量
                chargeList: [],
                chargeItem: "",
                payList: [],
                totalPrice: 0,
                payContent: "",
                bid: "",
                dataSource: {
                    "code": "1",
                    "message": "success",
                    "data": {
                        "pay": [
                            {
                                "id": 2,
                                "name": "支付宝",
                                "icon": "https://appadmin.newpais.com/upload/20240524/17964990422b3fb0c442fb9cbad29ae7.png",
                                "remark": "支付花呗",
                                "status": 1
                            }
                        ],
                        "goods": [
                            {
                                "id": 6,
                                "title": "网站开发",
                                "price": 100,
                                "num": 542,
                                "img_url": "https://tkadmin.newpais.com/upload/20240712/75896c5c5741b3214e1449b6ec224650.png",
                                "remark": ""
                            },
                            {
                                "id": 1,
                                "title": "小程序开发",
                                "price": 100,
                                "num": 375,
                                "img_url": "https://tkadmin.newpais.com/upload/20240712/75896c5c5741b3214e1449b6ec224650.png",
                                "remark": ""
                            },
                            {
                                "id": 2,
                                "title": "安卓开发",
                                "price": 100,
                                "num": 782,
                                "img_url": "https://tkadmin.newpais.com/upload/20240712/75896c5c5741b3214e1449b6ec224650.png",
                                "remark": ""
                            },
                            {
                                "id": 2,
                                "title": "苹果开发",
                                "price": 100,
                                "num": 265,
                                "img_url": "https://tkadmin.newpais.com/upload/20240712/75896c5c5741b3214e1449b6ec224650.png",
                                "remark": ""
                            },
                            {
                                "id": 3,
                                "title": "技术服务",
                                "price": 10,
                                "num": 1925,
                                "img_url": "https://tkadmin.newpais.com/upload/20240712/75896c5c5741b3214e1449b6ec224650.png",
                                "remark": ""
                            }
                        ]
                    }
                }
            }
        },
        created(){
            const aa = this.$route.query.bd_vid;
            if(aa!=undefined){
                this.bid = aa;
            }
            this.getData();
        },
        methods: {
            async getData(){
                // const res = await this.request.get("/api/home");
                const res = this.dataSource;
                console.log(res);
                if(res.code == 1){
                    this.chargeList = res.data.goods;
                    this.chargeItem = res.data.goods[this.active];
                    this.payList = res.data.pay;
                    this.sumPrice();
                }
            },
            handleNum(index){
                if(index == 1){
                    this.num  += 1;
                }else{
                    if(this.num > 1){
                        this.num += -1;
                    }
                }
                this.sumPrice();
            },
            handleClick(num){
                this.active = num;
                this.chargeItem = this.chargeList[this.active];
                this.num = 1;
                this.sumPrice();
            },
            sumPrice(){
                this.totalPrice = (this.chargeItem.price * this.num).toFixed(2);
            },
            handlePay(index){
                this.payActive = index
            },
            search(){
                this.$router.push({
                    name: "search"
                })
            },
            contact(){
                // const qqNumber = "123456"; // 替换为你的QQ号
                // const qqWebChatUrl = "http://wpa.qq.com/msgrd?v=3&uin=" + qqNumber + "&site=qq&menu=yes";
                // window.location.href = qqWebChatUrl;
                this.$alert('企鹅Q：721296778', '联系方式', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${ action }`
                        });
                    }
                });
            },
            async startOrder(){
                const data = {
                    title: this.chargeItem.title,
                    price: this.chargeItem.price,
                    num: this.num,
                    total: this.totalPrice,
                    type: this.payList[this.payActive].name,
                    ua: navigator.userAgent,
                    bid: this.bid,
                };
                const res = await this.request.post("/api/create_order",data);
                if(res.code == 1){
                    this.trade = res.data.trade;
                    if(this.payList[this.payActive].name == "支付宝") {
                        axios.post("https://appadmin.newpais.com/api/start_pay", {trade: this.trade}).then(response => {
                            this.payContent = response.data;
                            // console.log(this.payContent);
                            this.$nextTick(() => {
                                this.getOrderState();
                                document.forms[0].submit();
                            })
                        }).catch(error => {
                            console.log(error);
                        })
                    }else{
                        this.$message.error('微信暂时无法支付!');
                    }
                }else{
                    this.$message.error('创建订单失败!');
                }
            },
            getOrderState(){
                console.log("开始查单");
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    let initTime = +new Date();//获取到当前时间戳
                    const loading = this.$loading({
                        lock: true,
                        text: '加载中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let loop = () => {
                        // 间隔1s再去请求接口
                        setTimeout(() => {
                            //执行重复查询订单状态的代码
                            this.request.post("/api/get_pay_state",{trade: this.trade}).then((res) => {
                                if(res.code == 1 && res.data == 1){
                                    loading.close();
                                    // 开通会员后的操作
                                    this.$router.push({
                                        name: "result",
                                        query: {
                                            trade: this.trade,
                                        }
                                    })
                                }else{
                                    let now = +new Date();
                                    if(now - initTime < 50000){
                                        loop();
                                    }else{
                                        loading.close();
                                        this.$message.error('订单支付超时!');
                                    }
                                }
                            })
                        },1000)
                    }
                    loop();
                }, 500)
            },
        }
    }
</script>

<style scoped>
    .top_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 15px;
    }

    .top_left{
        display: flex;
        justify-content: center;
        align-items: end;
    }

    .top_h1{
        font-size: 1.7rem;
        color: #DC46D8;
    }

    .top_h2{
        font-size: 0.7rem;
        margin-left: 5px;
        margin-bottom: 5px;
        border: 1px solid black;
        padding: 2px 5px;
        border-radius: 20px;
        /* 关闭WebKit对文本大小的自动调整 */
        -webkit-text-size-adjust: none;
    }

    .app_box{
        box-sizing: border-box;
        width: 100%;
        /*background: #F4F4F4;*/
        background: F9F9F9;
        padding: 10px;
    }

    .app_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }

    .app_item{
        box-sizing: border-box;
        width: 24%;
        background: white;
        padding: 7px 5px;
        margin: 5px 0;
        display: flex;
        justify-content: start;
        align-items: center;
        border-radius: 5px;
    }

    .app_item p{
        font-size: 0.9rem;
        white-space: normal;
        word-wrap: break-word;
    }

    .app_item img{
        width: auto;
        height: 18px;
        margin-right: 3px;
    }

    .notice_box{
        background: #FFFBE7;
        width: 100%;
        padding: 3px 0;
    }

    .notice_box p{
        text-align: center;
        color: #B85D33;
    }

    .goods_box{
        width: calc(100% - 30px);
        margin: 20px 15px;
    }

    .goods_h1{
        border-left: 3px solid #409EFF;
        padding: 0 5px;
    }

    .goods_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px 0;
    }

    .goods_item{
        position: relative;
        box-sizing: border-box;
        width: 48%;
        padding: 8px 15px;
        border: 1px solid #E0E0E0;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        font-size: 0.9rem;
    }

    .goods_price{
        color: red;
    }

    .goods_tip{
        position: absolute;
        top: -20px;
        left: 10px;
        background: red;
        color: white;
        padding: 3px 6px;
        border-radius: 5px;
    }

    .pay_list{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pay_item{
        box-sizing: border-box;
        width: 48%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 0.9rem;
    }

    .item_left{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item_left img{
        width: 25px;
        margin-right: 3px;
    }

    .item_right{
        background: red;
        color: white;
        padding: 3px 6px;
        border-radius: 5px;
    }

    .btn_box{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-top: 1px solid silver;
        background: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .btn_t1{
        color: #409EFF;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn_t1 img{
        width: 25px;
        margin-right: 3px;
    }

    .detail_box{
        display: flex;
        justify-content: start;
        align-items: center;
        width: calc(100% - 15px);
        margin: 10px 15px;
    }

    .detail_info{
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }

    .detail_img{
        width: 100px;
        margin-right: 10px;
    }

    .detail_img img{
        width: 100%;
        border-radius: 10px;
    }

    .detail_num{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9rem;
    }

    .detail_info ul{
        display: flex;
        list-style: none;
    }

    .detail_info ul li{
        box-sizing: border-box;
        border: 1px solid black;
        margin-right: 5px;
        padding: 1px 5px;
        font-size: 0.8rem;
    }

    .detail_num_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
    }

    .num_text{
        font-size: 1rem;
        margin: 0 6px;
    }

    .num_btn{
        background: #409EFF;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: white;
        text-align: center;
        border-radius: 50%;
    }

    .submit_btn{
        background: linear-gradient(45deg,yellow,red);
        color: white;
        border: 0px;
        height: 40px;
        line-height: 40px;
        width: 90px;
        border-radius: 30px;
        font-size: 1rem;
    }
</style>
<template>
    <div class="con">
        <div class="search_title">
            <i class="el-icon-back" @click="back"></i>
            <p>订单详情</p>
        </div>
        <div class="search_box">
           <h3>查询订单</h3>
            <p style="margin: 10px 0">请输入商家的订单号：</p>
            <el-input v-model="input" placeholder="请输入商家订单号"></el-input>
            <div style="margin-top: 15px">
                <el-button type="primary" round @click="search">查询</el-button>
                <el-button round @click="reset">重置</el-button>
            </div>
            <p class="link" @click="getOrder(0)">订单号如何获取？</p>
            <p class="link" @click="getOrder(1)">关于退款？</p>
        </div>
        <div class="result_box" v-if="result">
            <p>订单号：</p>
            <p class="result_item">{{result.trade}}</p>
            <p style="margin-top: 15px">下载链接：</p>
            <p class="result_item">{{result.url}}</p>
            <div v-if="result.has_acc==1">
                <p style="margin-top: 15px">账号信息：</p>
                <div v-if="result.account">
                    <p class="result_item">{{result.account}}</p>
                </div>
                <div v-else>
                    <p class="result_item">待发货,请耐心等待!</p>
                </div>
            </div>
        </div>
        <div class="result_box" v-else>
            <p>暂无订单记录！</p>
        </div>
        <transition name="fade">
            <div class="code_bg" v-if="isOrder">
                <div class="code_box" v-if="num==0">
                    <div class="code_close" @click="getOrder(0)">×</div>
                    <div class="code_t1">获取订单号</div>
                    <div class="code_t2">1、打开支付宝，点击我的》账单》选择对应的订单点击进入查看。</div>
                    <img src="../assets/order.jpg" alt="">
                    <div class="code_btn" @click="getOrder(0)">确定</div>
                </div>
                <div class="code_box" v-if="num==1">
                    <div class="code_close" @click="getOrder(1)">×</div>
                    <div class="code_t1">关于退款</div>
                    <div class="code_t2">虚拟物品，容易复制，一经售出，概不退款！</div>
                    <div class="code_t2">感觉您的支持，还望理解！</div>
                    <div class="code_btn" @click="getOrder(1)">确定</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "SearchOrder",
        data(){
            return{
                input: '',
                result: "",
                isOrder: false,
                num: 0
            }
        },
        methods:{
            back(){
                this.$router.push({
                    name: "home"
                })
            },
            async search(){
                // this.result = this.input;
                const res = await this.request.post("/api/search",{trade: this.input});
                if(res.code == 1){
                    this.result = res.data;
                }
            },
            reset(){
                this.input = "";
            },
            getOrder(num){
                this.isOrder = !this.isOrder;
                this.num = num;
            }
        }
    }
</script>

<style scoped>
    .search_title{
        position: relative;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid silver;
    }

    .search_title p{
        text-align: center;
        line-height: 50px;
    }

    .el-icon-back{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .search_box{
        box-sizing: border-box;
        width: calc(100% - 30px);
        margin: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 20px 15px;
    }

    .result_box{
        box-sizing: border-box;
        width: calc(100% - 30px);
        margin: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 20px 15px;
    }
    .result_box p{
        line-height: 30px;
        /*超出div自动换行*/
        white-space: normal;
        word-wrap: break-word;
    }

    .result_item{
        box-sizing: border-box;
        background: #E5E5E5;
        padding: 5px 10px;
        border-radius: 5px;
        margin-top: 3px;
    }

    .code_bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0,0,0,.5);
    }

    .code_box{
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300px;
        transform: translateX(-50%) translateY(-50%);
        background: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .code_close{
        position: absolute;
        top: 5px;
        right: 12px;
        font-size: 25px;
    }

    .code_t1{
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
    }

    .code_t2{
        font-size: 16px;
        max-width: 250px;
        text-align: left;
        margin: 10px;
        color: black;
        /*超出div自动换行*/
        white-space: normal;
        word-wrap: break-word;
    }

    .code_box img{
        width: 100%;
        margin: 0 20px;
    }

    .code_btn{
        width: 250px;
        height: 40px;
        margin: 20px;
        line-height: 40px;
        background: #54A0FD;
        text-align: center;
        border-radius: 50px;
        color: white;
    }

    .link{
        margin: 15px 0 0 5px;
        font-size: 14px;
        color: #409EFF;
    }
</style>
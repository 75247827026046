<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  html{
    font-size: 15px;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #f9f9f9;
  }

  a{
    text-decoration: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #app {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .el-main{
    padding: 0 !important;
  }

  .el-message-box{
    width: calc(100% - 60px)!important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .slide-top-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-top-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-top-fade-enter, .slide-top-fade-leave-to
  {
    transform: translateY(100%);
    opacity: 0;
  }
</style>

import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import SearchOrder from "@/components/SearchOrder";
import Result from "@/components/Result";

Vue.use(Router);

const routes = [
    {
        path: "*",
        redirect: "/"
    },
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/search",
        name: "search",
        component: SearchOrder
    },
    {
        path: "/result",
        name: "result",
        component: Result
    }
];

export default new Router({
    mode: "history",
    routes
});
